import { useState } from "react";
import { useTranslation } from "react-i18next";

import { LayoutPage } from "layouts/LayoutPage";

import { Button } from "components/Button";
import { Card } from "components/Card";
import { PageHeader } from "components/PageHeader";
import { Text } from "components/Text";

import styles from "./PageFAQ.module.scss";

type Props = {
  translationKey: string;
};

export function PageFAQDetails({ translationKey }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<number[]>([]);

  const faqs = t(`pages.faq.questions.${translationKey}`, {
    returnObjects: true,
  }) as {
    question: string;
    answer: string;
  }[];

  const onClick = (index: number) => {
    if (open.includes(index)) {
      setOpen(open.filter((i) => i !== index));
    } else {
      setOpen([...open, index]);
    }
  };

  return (
    <LayoutPage bgColor="var(--background-primary)" paddingTop={120}>
      <div className={styles.content}>
        <PageHeader title={t(`pages.faq.${translationKey}`)} />

        <section className={styles.linksContainer}>
          {faqs.map((faq, index) => {
            const isOpen = open.includes(index);

            return (
              <Card key={index} noPadding className={styles.faqBtn}>
                <Button
                  text={faq.question}
                  icon={isOpen ? "chevronUp" : "chevronDown"}
                  intent="clear"
                  onClick={() => onClick(index)}
                  fill
                  textLeft
                />

                <>
                  {isOpen && (
                    <div className={styles.answer}>
                      <Text as="p" style="body">
                        {faq.answer}
                      </Text>
                    </div>
                  )}
                </>
              </Card>
            );
          })}
        </section>
      </div>
    </LayoutPage>
  );
}
