import { SubmitButtonProps, getSubmitButtonOptions } from "@rjsf/utils";

import { Button } from "components/Button";

import styles from "./BtnSubmit.module.scss";

export function BtnSubmit(props: SubmitButtonProps) {
  const { uiSchema } = props;
  const { submitText, norender } = getSubmitButtonOptions(uiSchema);

  if (norender) {
    return <></>;
  }

  return (
    <span className={styles.btnSubmit}>
      <Button
        intent="secondary"
        text={submitText}
        type="submit"
        icon="arrowRight"
      />
    </span>
  );
}
