import { FieldTemplateProps } from "@rjsf/utils";

import { classNames } from "lib/classNames";

import styles from "./Field.module.scss";

/**
 * Base structure for form elements
 * https://rjsf-team.github.io/react-jsonschema-form/docs/advanced-customization/custom-templates/#fieldtemplate
 */
export function Field(props: FieldTemplateProps) {
  const { help, description, rawErrors, children, uiSchema } = props;

  return (
    <div
      className={classNames({
        [styles.fieldContainerError]: rawErrors ? true : false,
      })}
      style={{
        width: uiSchema?.["ui:width"] ?? "100%",
      }}
    >
      {description}
      {children}
      {/* {errors} */}
      {help}
    </div>
  );
}
