import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { RJSFSchema, UiSchema } from "@rjsf/utils";

import { Form } from "components/Form";

import styles from "./InvoicePaymentCard.module.scss";

export function PaymentForm() {
  const [, setSearchParams] = useSearchParams();

  const onSubmit = () => {
    setSearchParams({ mockRef: "2" });
  };

  const { t } = useTranslation();

  const schema: RJSFSchema = {
    type: "object",
    required: ["name", "cardNumber", "expiryDate", "cvc"],
    properties: {
      name: {
        type: "string",
        title: t("pages.paymentForm.cardHolder"),
      },
      cardNumber: {
        type: "string",
        title: t("pages.paymentForm.cardNumber"),
        default: "4242 4242 4242 4242",
      },
      expiryDate: {
        type: "string",
        title: "MM/YY",
        maxLength: 5,
        default: "04/28",
      },
      cvc: {
        type: "string",
        title: t("pages.paymentForm.cvc"),
        maxLength: 4,
        default: "1234",
      },
    },
  };

  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      submitText: t("pages.paymentForm.continue"),
    },
    name: {
      "ui:widget": "text",
      "ui:icon": "user",
      "ui:autocomplete": "cc-name",
    },
    cardNumber: {
      "ui:widget": "text",
      "ui:icon": "creditCard",
      "ui:autocomplete": "cc-number",
    },
    expiryDate: {
      "ui:widget": "text",
      "ui:icon": "calendar",
      "ui:autocomplete": "cc-exp",
      "ui:width": "50%",
    },
    cvc: {
      "ui:widget": "text",
      "ui:icon": "lock",
      "ui:additionalIcon": "cvc",
      "ui:autocomplete": "cc-csc",
      "ui:width": "50%",
    },
  };

  return (
    <div className={styles.paymentForm} onClick={(e) => e.stopPropagation()}>
      <Form schema={schema} uiSchema={uiSchema} onSubmit={onSubmit} />
    </div>
  );
}
