import { CSSProperties } from "react";

import styles from "./SkeletonGroup.module.scss";

type SkeletonItem = {
  width: string;
  height: string;
  borderRadius: string;
  marginBottom?: string;
};

type MetaSkeletonItem = {
  marginBottom?: string;
  parentJustifyContent?: CSSStyleDeclaration["justifyContent"];
  parentAlignItems?: CSSStyleDeclaration["alignItems"];
  gap?: string;
  items: Array<SkeletonItem | MetaSkeletonItem>;
};

type SkeletonGroupProps = {
  options: Array<
    | SkeletonItem
    | SkeletonItem[]
    | {
        marginBottom?: string;
        parentJustifyContent?: CSSStyleDeclaration["justifyContent"];
        parentAlignItems?: CSSStyleDeclaration["alignItems"];
        gap?: string;
        items: Array<SkeletonItem | SkeletonItem[] | MetaSkeletonItem>;
      }
  >;
  loop?: number;
  wrapperStyles?: CSSProperties;
};

let uniqueId = 0;
const generateKey = () => `key-${uniqueId++}-${Date.now()}`;

export function SkeletonGroup({
  options,
  loop,
  wrapperStyles,
}: SkeletonGroupProps) {
  const buildContent = (opts: typeof options) => {
    const content: React.ReactNode[] = [];

    for (const item of opts) {
      // Single item
      // prettier-ignore
      if (!Array.isArray(item) && !("items" in item)) {
        content.push(
          <div
            key={generateKey()}
            className={styles.skeleton}
            style={{
              width: item.width,
              height: item.height,
              borderRadius: item.borderRadius,
              marginBottom: item.marginBottom ?? "",
            }}
          ></div>
        );
      } else if("items" in item) {
        content.push(
          <div
            key={generateKey()}
            className={styles.flex}
            style={{
              justifyContent: "parentJustifyContent" in item ? item.parentJustifyContent : "",
              alignItems: "parentAlignItems" in item ? item.parentAlignItems : "",
              gap: "gap" in item ? item.gap : "",              
              marginBottom: item.marginBottom,
            }}
          >
            {buildContent(item.items)}
          </div>
        );
      } else {
        content.push(<div key={generateKey()} className={styles.flex}>{buildContent(item)}</div>);
      }
    }

    return content;
  };

  const content = buildContent(options);

  if (!loop) {
    return (
      <div className={styles.wrapper} style={{ ...wrapperStyles }}>
        {content.map((el) => el)}
      </div>
    );
  }

  return (
    <div>
      {Array.from({ length: loop }, (_, i) => (
        <div key={i} className={styles.wrapper}>
          {content.map((el) => el)}
        </div>
      ))}
    </div>
  );
}
