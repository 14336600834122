import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { Card } from "components/Card";
import { CardAccordion } from "components/CardAccordion";
import { Icon } from "components/Icon";
import { Pill } from "components/Pill";
import { Text } from "components/Text";

import styles from "./InvoicePaymentCard.module.scss";
import { PaymentForm } from "./PaymentForm";
import { PaymentSwish } from "./PaymentSwish";

type Props = {
  provider: "trustly" | "swish" | "card";
  businessDays?: boolean;
  recommended?: boolean;
  onOpen?: () => void;
  price?: string;
  currency?: string;
  invoiceId: string;
};

type Config = {
  [key: string]: {
    title: string;
    provider: string;
    icon: JSX.Element | string;
    children?: JSX.Element;
  };
};

export function InvoicePaymentCard({
  provider,
  recommended,
  businessDays,
  onOpen,
  price,
  currency,
  invoiceId,
}: Props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const accordionOpenCallback = (isOpen: boolean) => {
    setIsOpen(isOpen);
    isOpen && onOpen?.();
  };

  const CONFIG: Config = {
    trustly: {
      title: t("features.invoice.payment.bankTransfer"),
      provider: t("features.invoice.payment.trustly"),
      icon: "/assets/trustly.svg",
    },
    swish: {
      title: t("features.invoice.payment.directPayment"),
      provider: t("features.invoice.payment.swish"),
      icon: "/assets/swish.svg",
      children: (
        <PaymentSwish
          price={price}
          currency={currency}
          invoiceId={invoiceId}
          isOpen={isOpen}
        />
      ),
    },
    card: {
      title: t("features.invoice.payment.card"),
      provider: t("features.invoice.payment.visaMastercard"),
      icon: <Icon name="card" />,
      children: <PaymentForm />,
    },
  };

  const btn = (
    <div className={styles.paymentCard}>
      <div className={styles.top}>
        <div>
          <Text as="p" style="h5">
            {CONFIG[provider].title}
          </Text>
          {recommended && (
            <Pill
              text={t("features.invoice.payment.recommended")}
              intent="clear"
            />
          )}
        </div>

        <>
          {typeof CONFIG[provider].icon === "string" ? (
            <img src={CONFIG[provider].icon as string} />
          ) : (
            CONFIG[provider].icon
          )}
        </>
      </div>

      <div>
        <Text as="p" style="body-small" color="--content-muted">
          {CONFIG[provider].provider}{" "}
          {businessDays ? t("features.invoice.payment.businessDays") : ""}
        </Text>
      </div>
    </div>
  );

  if (CONFIG[provider].children) {
    return (
      <CardAccordion btn={btn} onChange={accordionOpenCallback}>
        {CONFIG[provider]?.children ?? <></>}
      </CardAccordion>
    );
  }

  return (
    <Card noPadding>
      <Button intent="clear" fill>
        {btn}
      </Button>
    </Card>
  );
}
