import { Text } from "components/Text";

import { classNames } from "lib/classNames";

import styles from "./Pill.module.scss";

type Props = {
  text: string;
  intent?: "success" | "danger" | "clear";
};

export function Pill({ text, intent = "clear" }: Props) {
  return (
    <div
      className={classNames(styles.pill, {
        [styles.pillSuccess]: intent === "success",
        [styles.pillDanger]: intent === "danger",
      })}
    >
      <Text as="p" style="detail" color={intent}>
        {text}
      </Text>
    </div>
  );
}
