import { Link } from "react-router-dom";

import { Icon } from "components/Icon";
import { Text } from "components/Text";

import { Appearance } from "lib/types";

import type { FooterLink } from "./Footer";
import styles from "./Footer.module.scss";

type Props = {
  link: FooterLink;
  appearance: Appearance;
};

export function FooterLink({ link, appearance }: Props) {
  return (
    <Link to={link.href} className={styles.link}>
      <Text as="p" style="body-small">
        {link.title}
      </Text>
      <span>
        <Icon
          name={link.external ? "arrowUpRight" : "arrowRight"}
          intent={
            appearance === "dark"
              ? "--content-emphasis-inverted"
              : "--content-emphasis"
          }
        />
      </span>
    </Link>
  );
}
