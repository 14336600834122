import { useTranslation } from "react-i18next";

import { LayoutPage } from "layouts/LayoutPage";

import { Card } from "components/Card";
import { Icon } from "components/Icon";
import { PageHeader } from "components/PageHeader";
import { Switch } from "components/Switch";
import { Text } from "components/Text";
import { Tooltip } from "components/Tooltip";

import styles from "./PageNotifications.module.scss";

export function PageNotifications() {
  const { t } = useTranslation();

  return (
    <LayoutPage bgColor="transparent" paddingTop={120}>
      <div className={styles.content}>
        <PageHeader title={t("pages.notifications.title")} />

        <Card className={styles.card} noPadding>
          <div>
            <div className={styles.title}>
              <Text as="p" style="body" strong>
                {t("pages.notifications.invoiceNotifications")}
              </Text>
              <Tooltip text={t("pages.notifications.generalSettingsInfo")}>
                <Icon name="infoCircle" size={16} />
              </Tooltip>
            </div>
            <Text as="p" style="body" color="--content-subtle">
              {t("pages.notifications.choose")}
            </Text>
          </div>

          <section>
            <Text as="p" style="body" strong>
              {t("pages.notifications.email")}
            </Text>
            <Switch switched />
          </section>

          <section>
            <Text as="p" style="body" strong>
              {t("pages.notifications.sms")}
            </Text>
            <Switch switched />
          </section>

          <section>
            <div>
              <Text as="p" style="body" strong>
                {t("pages.notifications.print")}
              </Text>
              <Text as="p" style="body" color="--content-subtle">
                {t("pages.notifications.printFee")}
              </Text>
            </div>
            <Switch />
          </section>
        </Card>
      </div>
    </LayoutPage>
  );
}
