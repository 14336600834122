import { CSSProperties } from "react";

import { Icon, Icons } from "components/Icon";
import { Text } from "components/Text";

import { classNames } from "lib/classNames";

import styles from "./Button.module.scss";

type Props = {
  intent:
    | "primary"
    | "secondary"
    | "tertiary"
    | "clear"
    | "success"
    | "disabled";
  onClick?: () => void;
  text?: string;
  icon?: Icons;
  textLeft?: boolean;
  fill?: boolean;
  children?: JSX.Element | JSX.Element[];
  type?: "submit" | "reset" | "button";
  style?: CSSProperties;
  disabled?: boolean;
};

export function Button({
  text,
  intent,
  onClick,
  icon,
  textLeft,
  children,
  fill,
  type,
  style,
  disabled,
}: Props) {
  let intentValue;

  if (intent === "success") {
    intentValue = "--green-colour";
  } else {
    intentValue = intent === "primary" ? "--on-primary" : "--content-emphasis";
  }

  return (
    <button
      onClick={onClick}
      className={classNames(styles.button, {
        [styles.buttonPrimary]: intent === "primary",
        [styles.buttonSecondary]: intent === "secondary",
        [styles.buttonTertiary]: intent === "tertiary",
        [styles.buttonClear]: intent === "clear",
        [styles.buttonSuccess]: intent === "success",
        [styles.buttonDisabled]: intent === "disabled",
        [styles.buttonFill]: fill ? true : false,
        [styles.buttonLeftText]: textLeft && !icon ? true : false,
        [styles.buttonWithIcon]: icon ? true : false,
      })}
      type={type}
      style={style}
      disabled={disabled}
    >
      {/* Hidden element to accurately center items */}
      {icon && !textLeft && text && <span style={{ width: 24, height: 24 }} />}

      {text && (
        <Text as="p" style="body" strong>
          {text}
        </Text>
      )}

      {icon && <Icon name={icon} intent={intentValue} />}

      {children}
    </button>
  );
}
