import { CSSProperties } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Icon } from "components/Icon";
import { Text } from "components/Text";

import { IRootState } from "lib/store";

import styles from "./CardAd.module.scss";

type CardSizes = "21/9" | "16/9" | "3/2" | "1/1" | "4/5";

interface CustomStyles extends CSSProperties {
  "--card-size": CardSizes;
  "--card-bg-url-mobile": string;
  "--card-bg-url-desktop": string;
}

type Props = {
  title?: string;
  href: string;
  size?: CardSizes; // only applies for mobile viewports
  text?: string;
};

export function CardAd({ title, href, text, size = "21/9" }: Props) {
  const theme = useSelector((state: IRootState) => state.theme.theme);

  return (
    <Link to={href} className={styles.cardAdContainer}>
      <article
        className={styles.cardAd}
        style={
          {
            "--card-size": size,
            "--card-bg-url-mobile": `url('/assets/${theme}/ad-mobile.png')`,
            "--card-bg-url-desktop": `url('/assets/${theme}/ad-desktop.png')`,
          } as CustomStyles
        }
      >
        <div className={styles.header}>
          <Text as="h3" style="h3" color="--content-emphasis">
            {title}
          </Text>
          <Icon name="arrowRight" intent="--content-emphasis" size={32} />
        </div>
        {text && (
          <Text as="p" style="body-small">
            {text}
          </Text>
        )}
      </article>
    </Link>
  );
}
