import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { RInioInputRadio } from "ui-core";

import { Icon } from "components/Icon";

import { classNames } from "lib/classNames";
import { setLanguage } from "lib/slices/languageSlice";
import { IRootState } from "lib/store";
import { Appearance } from "lib/types";

import i18n from "utils/i18n";

import styles from "./Navbar.module.scss";

type LanguageSwitcherProps = {
  isOpen: boolean;
  onClick: () => void;
  appearance: Appearance;
};

export const LanguageSwitcher = forwardRef<
  HTMLDivElement,
  LanguageSwitcherProps
>(({ isOpen, onClick, appearance }, ref) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const language = useSelector((state: IRootState) => state.language.language);

  const handleChangeLanguage = (event: { detail: { value: string } }) => {
    dispatch(setLanguage(event.detail.value));
  };

  return (
    <div className={styles.languageSwitcherWrapper} ref={ref}>
      <span
        className={styles.languageSwitcherIcon}
        aria-label={t("common.pickLanguage")}
        onClick={onClick}
      >
        <Icon
          name="globe"
          intent={
            appearance === "dark"
              ? "--content-emphasis-inverted"
              : "--content-emphasis"
          }
        />
      </span>

      <div className={classNames(styles.menu, { [styles.menuShow]: isOpen })}>
        <strong>{t("common.language")}</strong>
        <RInioInputRadio
          name="language-options"
          defaultValue={language}
          options={Object.keys(
            i18n.options.resources as Record<string, object>,
          ).map((key) => ({
            label: key.toUpperCase(),
            value: key,
            // TODO it doesn't work somehow
            checked: key === language,
          }))}
          onChange={handleChangeLanguage}
          intent="solid-circle"
        />
      </div>
    </div>
  );
});

LanguageSwitcher.displayName = "LanguageSwitcher";
