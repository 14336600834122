import { configureStore } from "@reduxjs/toolkit";

import { customerServiceSlice } from "./slices/customerServiceSlice";
import { invoiceServiceSlice } from "./slices/invoiceServiceSlice";
import languageSlice from "./slices/languageSlice";
import { paymentServiceSlice } from "./slices/paymentServiceSlice";
import themeSlice from "./slices/themeSlice";
import userSlice from "./slices/userSlice";

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(invoiceServiceSlice.middleware)
      .concat(paymentServiceSlice.middleware)
      .concat(customerServiceSlice.middleware),
  reducer: {
    theme: themeSlice,
    language: languageSlice,
    user: userSlice,
    [invoiceServiceSlice.reducerPath]: invoiceServiceSlice.reducer,
    [paymentServiceSlice.reducerPath]: paymentServiceSlice.reducer,
    [customerServiceSlice.reducerPath]: customerServiceSlice.reducer,
  },
});

export default store;
export type IRootState = ReturnType<typeof store.getState>;
