import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { setUser } from "lib/slices/userSlice";
import { IRootState } from "lib/store";
import { User } from "lib/types";

import { PageError } from "pages/PageError";

type Props = {
  children: JSX.Element | JSX.Element[];
};
export function AuthProvider({ children }: Props) {
  const user = useSelector((state: IRootState) => state.user.user);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(
      decodeURIComponent(window.location.search),
    );
    const customerId = params.get("customerId");

    function setError() {
      dispatch(setUser(undefined));
    }

    async function fetchUser(customerId: string) {
      // TODO: Backend should not return sensitive user data
      const res = await fetch(
        `${process.env.REACT_APP_API_CUSTOMER_URL}/customers/${customerId}`,
      );

      if (!res.ok) {
        setError();
        return;
      }

      const json = await res.json();

      // No user found with customerId
      if (!json) {
        setError();
        return;
      }

      const user: User = json;
      dispatch(setUser(user));
    }

    if (user && customerId && String(user?.id) !== customerId) {
      fetchUser(customerId);
      return;
    }

    if (user) {
      return;
    }

    if (!customerId) {
      setError();
      return;
    }

    fetchUser(customerId);
  }, [user]);

  if (!user) {
    const router = createBrowserRouter([
      {
        path: "*",
        element: (
          <PageError
            title={t("pages.errors.401.title")}
            message={t("pages.errors.401.message")}
          />
        ),
      },
    ]);

    return <RouterProvider router={router} />;
  }

  return children;
}
