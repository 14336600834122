import { LayoutPage } from "layouts/LayoutPage";
import { RInioText } from "ui-core";

import { Card } from "components/Card";
import { PageHeader } from "components/PageHeader";

type Props = {
  title: string;
  message: string;
};

export function PageError({ title, message }: Props) {
  return (
    <LayoutPage bgColor="var(--background-primary)" paddingTop={120}>
      <div className="content">
        <Card>
          <PageHeader title={title} />
          <br />
          <RInioText as="p" text={message} />
        </Card>
      </div>
    </LayoutPage>
  );
}
