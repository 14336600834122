import useMediaQuery from "lib/hooks/useMediaQuery";

/**
 * A hook that wraps useMediaQuery and detects if the user is on a mobile viewport
 */
const useIsMobile = (): boolean => {
  return useMediaQuery("(max-width: 768px)");
};

export default useIsMobile;
