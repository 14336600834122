import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Icon } from "components/Icon";
import { Text } from "components/Text";

import { classNames } from "lib/classNames";

import styles from "./Navbar.module.scss";

type Props = {
  isOpen: boolean;
  onClick: () => void;
};

export function Menu({ isOpen, onClick }: Props) {
  const { t } = useTranslation();

  const LINKS: { [key: string]: string } = {
    // "/profile": t("components.navbar.profile"), Hidden because it's out of scope atm (AS-763)
    // "/notifications": t("components.navbar.notifications"), commented out due to out of scope (AS-759)
    "/privacy": t("components.navbar.privacy"),
    "/terms": t("components.navbar.terms"),
  };

  return (
    <div className={classNames(styles.menu, { [styles.menuShow]: isOpen })}>
      {Object.keys(LINKS).map((key) => (
        <Link key={key} to={key} onClick={onClick}>
          <Text as="p" style="body">
            {LINKS[key]}
          </Text>
          <Icon name="chevronRight" />
        </Link>
      ))}
    </div>
  );
}
