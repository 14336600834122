import { useSelector } from "react-redux";

import { IRootState } from "lib/store";

const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

export function useHead(title: string) {
  const theme = useSelector((state: IRootState) => state.theme.theme);

  document.title = `${title} - ${capitalize(theme)}`;
}
