import { ReactNode, createElement } from "react";

import { classNames } from "lib/classNames";

import styles from "./Text.module.scss";

type Props = {
  as: "h1" | "h2" | "h3" | "h4" | "h5" | "p" | "label";
  style: keyof typeof styles;
  children: ReactNode;
  className?: string;
  color?: string;
  htmlFor?: string;
  strong?: boolean;
};

export function Text({
  as,
  style,
  children,
  htmlFor,
  className = "",
  color,
  strong,
}: Props) {
  return createElement(
    as,
    {
      className: classNames(styles[style], className),
      style: { color: `var(${color})`, fontWeight: strong ? "500" : undefined },
      htmlFor,
    },
    children,
  );
}
