import { ObjectFieldTemplateProps } from "@rjsf/utils";

import styles from "./Fieldset.module.scss";

export function Fieldset(props: ObjectFieldTemplateProps) {
  return (
    <fieldset className={styles.fieldset}>
      {props.title}
      {props.description}
      {props.properties.map((element) => element.content)}
    </fieldset>
  );
}
