import { useNavigate } from "react-router-dom";

/** Hook to go back to the preview history stack, or, if none, go to the index page */
export function useGoBack() {
  const navigate = useNavigate();

  const goBack = () => {
    if (window.history.length === 1) {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  return goBack;
}
