import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Icon } from "components/Icon";
import { Notification } from "components/Notification";
import { Pill } from "components/Pill";
import { Text } from "components/Text";

import { convertKebabIntoCamelCased } from "lib/helpers";
import { InvoiceStatus } from "lib/types";

import styles from "./InvoiceContainer.module.scss";

type Props = {
  state: InvoiceStatus;
  invoiceId: string;
  displayId: string;
  date: string;
  amount: string;
  currency: string;
};

export function InvoiceContainer({
  state,
  invoiceId,
  displayId,
  date,
  amount,
  currency,
}: Props) {
  const { t } = useTranslation();

  const statusText = t(
    `features.invoice.backendStatusTextTranslationMapping.${convertKebabIntoCamelCased(state)}`,
  );

  return (
    <Link to={`/${invoiceId}`} className={styles.invoiceContainer}>
      <div className={styles.status}>
        <Notification
          intent={state === "paid" ? "--color-green" : "--color-red"}
        />
        <Text as="p" style="body">
          {t("features.invoice.invoiceId", { id: displayId })}
        </Text>
      </div>

      <div className={styles.info}>
        <div>
          {state === "processing" && <Pill text={statusText} />}
          <Text as="p" style="body-small" className={styles.date}>
            {date}
          </Text>
          <Text as="p" style="body">
            {amount} {currency}
          </Text>
        </div>
        <Icon name="chevronRight" />
      </div>
    </Link>
  );
}
