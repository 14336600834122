import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { LayoutApp } from "layouts/LayoutApp";
import { AuthProvider } from "providers/AuthProvider";

import { IRootState } from "lib/store";

import { PageAllInvoices } from "pages/PageAllInvoices";
import { PageFAQ, PageFAQDetails } from "pages/PageFAQ";
import { PageHome } from "pages/PageHome";
import { PageInvoiceDetails } from "pages/PageInvoiceDetails";
import { PageNotifications } from "pages/PageNotifications";
import { PagePayment } from "pages/PagePayment";
import { PagePrivacy } from "pages/PagePrivacy";
import { PageProfile } from "pages/PageProfile";
import { PageTerms } from "pages/PageTerms";

const router = createBrowserRouter([
  {
    element: <LayoutApp />,
    children: [
      {
        path: "/",
        element: <PageHome />,
        // TODO: What is the homepage?
      },
      {
        path: "/:id",
        element: <PageHome />,
      },
      {
        path: "/:id/details",
        element: <PageInvoiceDetails />,
      },
      {
        path: "/:id/payment",
        element: <PagePayment />,
      },
      {
        path: "/all-invoices",
        element: <PageAllInvoices />,
      },
      {
        path: "/profile",
        element: <PageProfile />,
      },
      {
        path: "/notifications",
        element: <PageNotifications />,
      },
      {
        path: "/privacy",
        element: <PagePrivacy />,
      },
      {
        path: "/terms",
        element: <PageTerms />,
      },
      {
        path: "/faq",
        element: <PageFAQ />,
      },
      {
        path: "/faq/refunds",
        element: <PageFAQDetails translationKey="refunds" />,
      },
      {
        path: "/faq/declined-payments",
        element: <PageFAQDetails translationKey="declinedPayments" />,
      },
      {
        path: "/faq/frauds",
        element: <PageFAQDetails translationKey="frauds" />,
      },
      {
        path: "/faq/account-privacy",
        element: <PageFAQDetails translationKey="accountPrivacy" />,
      },
    ],
  },
]);

export default function App(): JSX.Element {
  const theme = useSelector((state: IRootState) => state.theme.theme);

  useEffect(() => {
    document
      .querySelector("link[rel='icon']")
      ?.setAttribute("href", `/assets/${theme}/favicon.ico`);
    document
      .querySelector("link[rel='ap']")
      ?.setAttribute("href", `/assets/${theme}/favicon.ico`);
  }, [theme]);

  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}
