import { useTranslation } from "react-i18next";

import { LayoutPage } from "layouts/LayoutPage";
import { RInioText } from "ui-core";

import { PageHeader } from "components/PageHeader";

export function PagePrivacy() {
  const { t } = useTranslation();

  return (
    <LayoutPage bgColor="var(--background-primary)" paddingTop={120}>
      <div className="content">
        <PageHeader title={t("pages.privacy.title")} />

        <section style={{ marginTop: 16 }}>
          <RInioText as="p" text={t("pages.privacy.content")} />
        </section>
      </div>
    </LayoutPage>
  );
}
