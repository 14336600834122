import { useState } from "react";

import RJSForm, { FormProps } from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";

import { BtnSubmit } from "./BtnSubmit/BtnSubmit";
import { Field } from "./Field/Field";
import { Fieldset } from "./Fieldset/Fieldset";
import { Input } from "./Input/Input";

const customTemplates = {
  BaseInputTemplate: Input,
  FieldTemplate: Field,
  ObjectFieldTemplate: Fieldset,
  ButtonTemplates: { SubmitButton: BtnSubmit },
};
const customWidgets = {};

export function Form(props: Omit<FormProps, "validator">) {
  // Due to https://github.com/rjsf-team/react-jsonschema-form/issues/2395, there's a
  // bug in rjsf which resets the form state onSubmit, taking control of the state fixes
  // the issue, hence why we have it here.
  const [formData, setFormData] = useState();

  return (
    <RJSForm
      templates={customTemplates}
      idPrefix="form"
      widgets={customWidgets}
      formData={formData}
      onChange={(e) => {
        setFormData(e.formData);
      }}
      validator={validator}
      showErrorList={false}
      {...props}
    />
  );
}
