import { CSSProperties } from "react";

import styles from "./Notification.module.scss";

interface CustomStyles extends CSSProperties {
  "--notification-intent": string;
}

type Props = {
  intent: string;
};

export function Notification({ intent }: Props) {
  return (
    <span
      className={styles.notification}
      style={
        {
          "--notification-intent": `var(${intent})`,
        } as CustomStyles
      }
    />
  );
}
