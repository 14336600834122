import { useState, useEffect } from "react";

/**
 * A hook that listens for changes to a CSS media query.
 *
 * @param {string} query - The CSS media query to listen for.
 * @returns {boolean} matches - Whether the media query currently matches.
 *
 * @example
 * const isDesktop = useMediaQuery('(min-width: 1024px)');
 */
const useMediaQuery = (query: string): boolean => {
  const media = window.matchMedia(query);
  const [matches, setMatches] = useState<boolean>(media.matches);

  useEffect(() => {
    const listener = () => setMatches(media.matches);
    media.addEventListener("change", listener);

    return () => media.removeEventListener("change", listener);
  }, [query]);

  return matches;
};

export default useMediaQuery;
