import { classNames } from "lib/classNames";

import styles from "./Card.module.scss";

type Props = {
  children: JSX.Element | JSX.Element[];
  noPadding?: boolean;
  border?: string;
  className?: string;
};

export function Card({ children, border, className, noPadding }: Props) {
  return (
    <section
      style={{ padding: noPadding ? 0 : undefined, border }}
      className={classNames(styles.card, className ?? "")}
    >
      {children}
    </section>
  );
}
