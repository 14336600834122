import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { Checkbox } from "components/Form";
import { Icon } from "components/Icon";
import { Pill } from "components/Pill";
import { Text } from "components/Text";

import { UnpaidInvoice } from "./InvoiceHero";
import styles from "./InvoiceHero.module.scss";

type Props = {
  unpaidInvoices: UnpaidInvoice[];
  onSelected: (value: number) => void;
};

export function MoreUnpaid({ unpaidInvoices, onSelected }: Props) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<UnpaidInvoice[]>([]);

  const onSelectAll = () => {
    selected.length === unpaidInvoices.length
      ? setSelected([])
      : setSelected(unpaidInvoices.map((i) => i));
  };

  const onSelect = (invoice: UnpaidInvoice) => {
    if (selected.includes(invoice)) {
      setSelected(selected.filter((i) => i.id !== invoice.id));
    } else {
      setSelected([...selected, invoice]);
    }
  };

  useEffect(() => {
    const totalAmount = selected
      .map((item) => parseFloat(item.amount))
      .reduce((acc, curr) => acc + curr, 0);

    onSelected(totalAmount);
  }, [selected]);

  return (
    <div className={styles.moreUnpaid}>
      <div>
        <Text as="p" style="body-small">
          {t("features.invoice.moreUnpaid")}
        </Text>
        <Button intent="clear" onClick={onSelectAll}>
          <Text as="p" style="body-small">
            {selected.length === unpaidInvoices.length
              ? t("features.invoice.deselectAll")
              : t("features.invoice.selectAll")}
          </Text>
        </Button>
      </div>

      <div className={styles.invoices}>
        {unpaidInvoices.map((invoice, index) => (
          <Button
            key={index}
            intent="clear"
            onClick={() => onSelect(invoice)}
            fill
          >
            <div className={styles.unpaidInvoice}>
              <Checkbox checked={selected.includes(invoice)} />

              <div>
                <div>
                  <Text as="h4" style="h2">
                    {t("features.invoice.invoiceId", { id: invoice.displayId })}
                  </Text>
                  <Text as="p" style="body-small" className={styles.dueDate}>
                    {t("features.invoice.dueDate", { date: invoice.dueDate })}
                  </Text>
                </div>
                {/* TODO: Calculate dynamic time */}
                <Pill text="14 days left" />
                <Text as="p" style="body">
                  {invoice.amount} {invoice.currency}
                </Text>
                <Icon name="chevronRight" />
              </div>
            </div>
          </Button>
        ))}
      </div>
    </div>
  );
}
