import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, ScrollRestoration, useSearchParams } from "react-router-dom";

import { Footer } from "components/Footer";
import { Navbar } from "components/Navbar";

import { setLanguage } from "lib/slices/languageSlice";
import { setTheme } from "lib/slices/themeSlice";
import { IRootState } from "lib/store";

import i18n from "utils/i18n";

import styles from "./LayoutApp.module.scss";

const THEME_TO_NAME: Record<string, string> = {
  epark: "ePark",
  redcross: "Red Cross",
};

export function LayoutApp() {
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const theme = useSelector((state: IRootState) => state.theme.theme);
  const { t } = useTranslation();

  useEffect(() => {
    const themeQuery = searchParams.get("c");
    dispatch(setTheme(themeQuery));
  }, [searchParams]);

  // This is here to update the html lang attribute on first load
  useEffect(() => {
    dispatch(setLanguage(i18n.language));
    dispatch(setTheme(localStorage.getItem("theme")));
  }, []);

  // TODO: Change me with actual company data
  const company = {
    name: THEME_TO_NAME[theme] ?? theme,
    logoSrc: `/assets/${theme}/logo.png`,
    links: [
      {
        title: t("components.footer.website"),
        href: "#",
        external: true,
      },
      {
        title: t("components.footer.support"),
        href: "/support",
      },
      {
        title: t("components.footer.account"),
        href: "/account",
      },
    ],
    socials: [
      {
        title: "Instagram",
        href: "#",
        external: true,
      },
      {
        title: "Facebook",
        href: "#",
        external: true,
      },
      {
        title: "Twitter",
        href: "#",
        external: true,
      },
    ],
  };

  return (
    <>
      <Navbar companyName={company.name} logoSrc={company.logoSrc} />
      <main className={styles.main}>
        <Outlet />
      </main>
      <Footer
        companyName={company.name}
        logoSrc={company.logoSrc}
        appearance={"dark"}
        links={company.links}
        socials={company.socials}
      />
      <ScrollRestoration />
    </>
  );
}
