import { useEffect } from "react";

/**
 * Detects and responds to clicks outside of a specified element.
 */
export function useOutsideClick(
  ref:
    | React.RefObject<HTMLElement>
    | Array<
        | React.RefObject<HTMLElement>
        | {
            key: string;
            element: React.RefObject<HTMLElement>;
          }
      >,
  callback: (type?: string) => void,
): void {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!Array.isArray(ref)) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          callback();
        }
      } else {
        ref.forEach((r) => {
          if ("current" in r) {
            if (r.current && !r.current.contains(event.target as Node)) {
              callback();
            }
          } else {
            if (
              r.element.current &&
              !r.element.current.contains(event.target as Node)
            ) {
              callback(r.key);
            }
          }
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}
