import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button } from "components/Button";
import { Card } from "components/Card";
import { CardInfo } from "components/CardInfo";
import { Pill } from "components/Pill";
import { Text } from "components/Text";

import {
  convertKebabIntoCamelCased,
  formatNumberToCurrency,
} from "lib/helpers";
import { InvoiceStatus } from "lib/types";

import styles from "./InvoiceHero.module.scss";
import { MoreUnpaid } from "./MoreUnpaid";

export type UnpaidInvoice = {
  id: string;
  displayId: string;
  dueDate: string;
  amount: string;
  currency: string;
};

type Props = {
  invoiceTitle: string;
  invoiceId: string;
  displayId: string;
  amount: string;
  currency: string;
  dueDate: string;
  reminderFee?: string | null;
  status: InvoiceStatus;
  moreUnpaid?: UnpaidInvoice[];
};

export function InvoiceHero({
  invoiceId,
  invoiceTitle,
  displayId,
  amount,
  currency,
  dueDate,
  reminderFee,
  moreUnpaid,
  status,
}: Props) {
  const { t } = useTranslation();
  const [totalAmount, setTotalAmount] = useState(amount);

  const onUnpaidSelected = (selectedTotal: number) => {
    const withMainInvoiceAmount = parseFloat(amount) + selectedTotal;
    const localString = formatNumberToCurrency(withMainInvoiceAmount);
    setTotalAmount(localString);
  };

  const statusText = t(
    `features.invoice.backendStatusTextTranslationMapping.${convertKebabIntoCamelCased(status)}`,
  );

  if (status === "debtCollection") {
    return (
      <Card>
        <div className={styles.header}>
          <div className={styles.title}>
            <div>
              <Text as="p" style="h2">
                {invoiceTitle}
              </Text>
              {displayId && (
                <Text as="p" style="body">
                  {t("features.invoice.invoiceNumber")} {displayId}
                </Text>
              )}
            </div>
            <Pill text={statusText} intent="danger" />
          </div>

          <Link to={`/${invoiceId}/details`}>
            <Text as="p" style="body-small">
              {t("features.invoice.details")}
            </Text>
          </Link>
        </div>

        <div className={styles.content}>
          <CardInfo
            withWarning
            text={t("features.invoice.infoCard.debtCollection", {
              date: dueDate,
            })}
          />
        </div>

        <Link to="https://intrum.se" target="_blank">
          <Button
            text={t("features.invoice.payIntrum")}
            intent="primary"
            icon="arrowUpRight"
            onClick={() => undefined}
          />
        </Link>
      </Card>
    );
  }

  if (status === "processing") {
    return (
      <Card>
        <div className={styles.header}>
          <div className={styles.title}>
            <div>
              <Text as="p" style="h2">
                {invoiceTitle}
              </Text>
              {displayId && (
                <Text as="p" style="body">
                  {t("features.invoice.invoiceNumber")} {displayId}
                </Text>
              )}
            </div>
            <Pill text={statusText} intent="clear" />
          </div>

          <Link to={`/${invoiceId}/details`}>
            <Text as="p" style="body-small">
              {t("features.invoice.details")}
            </Text>
          </Link>
        </div>

        <div className={styles.content} style={{ marginBottom: 4 }}>
          <Text as="h2" style="h1">
            {amount} {currency}
          </Text>
        </div>
      </Card>
    );
  }

  if (status === "paid") {
    return (
      <Card>
        <div className={styles.header}>
          <div className={styles.title}>
            <div>
              <Text as="p" style="h2">
                {invoiceTitle}
              </Text>
              <Text as="p" style="body">
                {t("features.invoice.invoiceNumber")} {displayId}
              </Text>
            </div>
            <Pill text={statusText} intent="success" />
          </div>

          <Link to={`/${invoiceId}/details`}>
            <Text as="p" style="body-small">
              {t("features.invoice.details")}
            </Text>
          </Link>
        </div>

        <div className={styles.content} style={{ marginBottom: 4 }}>
          <Text as="h2" style="h1" color="--color-green">
            {amount} {currency}
          </Text>
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <div className={styles.header}>
        <div className={styles.title}>
          <div>
            <Text as="p" style="h2">
              {invoiceTitle}
            </Text>
            <Text as="p" style="body">
              {t("features.invoice.invoiceNumber")} {displayId}
            </Text>
          </div>
          {/* prettier-ignore */}
          {(status === "reminder" || reminderFee) && (
            <Pill text={statusText} intent="danger" />
          )}
        </div>

        <Link to={`/${invoiceId}/details`}>
          <Text as="p" style="body-small">
            {t("features.invoice.details")}
          </Text>
        </Link>
      </div>

      <div className={styles.content}>
        <Text as="h2" style="h1">
          {amount} {currency}
        </Text>
        {/* prettier-ignore */}
        {(status === "reminder" || reminderFee) && (
          <Text as="p" style="body">
            {t("features.invoice.reminderFee", {
              amount: reminderFee || 60,
              currency,
            })}
          </Text>
        )}
        <div className={styles.dueDate}>
          <span className={styles.alertCircle} />
          <Text as="p" style="body" color="--content-subtle">
            {t("features.invoice.dueDate", { date: dueDate })}
          </Text>
        </div>
      </div>

      <>
        {/* prettier-ignore */}
        {(status === "reminder" || reminderFee) && (
          <div className={styles.infoMsg}>
            <CardInfo
              text={t("features.invoice.infoCard.avoidDebtCollection", {
                date: dueDate,
              })}
            />
          </div>
        )}
      </>

      <>
        {status === "direct-debit" && (
          <CardInfo
            text={t("features.invoice.infoCard.directDebit", {
              date: dueDate,
            })}
          />
        )}
      </>

      <>
        {status === "e-invoice" && (
          <CardInfo text={t("features.invoice.infoCard.eInvoice")} />
        )}
      </>

      <>
        {moreUnpaid && (
          <MoreUnpaid
            unpaidInvoices={moreUnpaid}
            onSelected={onUnpaidSelected}
          />
        )}
      </>

      <>
        {(status === "created" ||
          status === "sent" ||
          status === "reminder") && (
          <div className={styles.payCta}>
            <Link to={`/${invoiceId}/payment`}>
              <Button
                text={t("features.invoice.pay", {
                  amount: totalAmount,
                  currency,
                })}
                intent="primary"
                icon="arrowRight"
                onClick={() => undefined}
              />
            </Link>
            <div>
              <img
                alt={t("common.logoOf", { company: "Trustly" })}
                src="/assets/trustly.svg"
              />
              <img
                alt={t("common.logoOf", { company: "Swish" })}
                src="/assets/swish.svg"
              />
            </div>
          </div>
        )}
      </>
    </Card>
  );
}
