import { Icon } from "components/Icon";
import { Text } from "components/Text";

import styles from "./CardInfo.module.scss";

type Props = {
  text: string;
  withWarning?: boolean;
};

export function CardInfo({ text, withWarning }: Props) {
  return (
    <div className={styles.cardInfo}>
      {withWarning && (
        <Icon name="alertTriangle" intent="--color-red" size={20} />
      )}
      <Text as="p" style="body-small">
        {text}
      </Text>
    </div>
  );
}
