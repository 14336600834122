import { useEffect, useRef, useState } from "react";

import { Icon } from "components/Icon";
import { Text } from "components/Text";
import { Tooltip } from "components/Tooltip";

import { classNames } from "lib/classNames";

import styles from "./TabsHeaders.module.scss";

type TabsHeadersProps = {
  tabs: Array<{
    title: string;
    tooltipText?: string;
  }>;
  activeIndex: number;
  onChange: (index: number) => void;
};

export function TabsHeaders({ tabs, activeIndex, onChange }: TabsHeadersProps) {
  // Refs
  const tabsWrapperRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<Map<string, HTMLDivElement> | null>(null);
  const borderRef = useRef<HTMLDivElement>(null);
  const initialRender = useRef(true);

  const borderPos =
    // prettier-ignore
    tabsWrapperRef.current && itemsRef.current
      ? ((itemsRef.current!.get(String(activeIndex))!.getBoundingClientRect().left -
          tabsWrapperRef.current!.getBoundingClientRect().left) /
          tabsWrapperRef.current.clientWidth) *
        100
      : 0;

  // Border width is put in state because
  // when resized, there is a problem with the border width not being adjusted properly
  const [borderWidth, setBorderWidth] = useState("0");

  const handleChange = (ind: number) => {
    onChange(ind);
    initialRender.current = false;

    setBorderWidth(
      `${
        itemsRef.current
          ? ((itemsRef.current!.get(String(activeIndex))!.clientWidth - 4) /
              tabsWrapperRef.current!.clientWidth) *
            100
          : 0
      }%`,
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setBorderWidth(
        `${
          ((itemsRef.current!.get(String(activeIndex))!.clientWidth - 4) /
            tabsWrapperRef.current!.clientWidth) *
          100
        }%`,
      );
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getMap() {
    if (!itemsRef.current) {
      itemsRef.current = new Map();
    }

    return itemsRef.current;
  }

  return (
    <div className={styles.tabsWrapper}>
      <div
        className={`${styles.tabsContent} ${initialRender.current ? styles.initial : ""}`}
        ref={tabsWrapperRef}
      >
        {tabs.map((tab, ind) => (
          <div
            className={classNames({
              [styles.tabItemWithTooltip]: !!tab.tooltipText,
            })}
            aria-label={tab.title}
            aria-pressed={ind === activeIndex}
            role="button"
            tabIndex={0}
            key={tab.title}
            onClick={() => handleChange(ind)}
            ref={(node) => {
              const map = getMap();

              if (node) {
                map.set(String(ind), node);
              } else {
                map.delete(String(ind));
              }
            }}
          >
            <Text
              as="p"
              style="body"
              strong
              color={
                ind === activeIndex ? "--content-emphasis" : "--content-muted"
              }
            >
              {tab.title}
            </Text>
            {tab.tooltipText && (
              <span>
                <Tooltip text={tab.tooltipText}>
                  <Icon
                    name="infoCircle"
                    size={18}
                    intent={
                      ind === activeIndex
                        ? "--content-emphasis"
                        : "--content-muted"
                    }
                  />
                </Tooltip>
              </span>
            )}
          </div>
        ))}

        <div
          ref={borderRef}
          className={styles.border}
          style={{
            left: `${borderPos}%`,
            width: borderWidth,
          }}
        ></div>
      </div>
    </div>
  );
}
