import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { User } from "lib/types";

import { updateUser } from "./userSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_CUSTOMER_URL,
  credentials: "include",
});

export const customerServiceSlice = createApi({
  reducerPath: "customerService",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getCustomer: builder.query<User, string>({
      query: (id) => `/customers/${id}`,
    }),
    updateCustomer: builder.mutation<
      User,
      { id: string; payload: Partial<User> }
    >({
      query: (updatedCustomer) => ({
        url: `/customers/${updatedCustomer.id}`,
        method: "PATCH",
        body: updatedCustomer.payload,
      }),
      transformErrorResponse: (response) => {
        if (Object.hasOwnProperty.call(response.data, "error")) {
          return (response.data as { error: string }).error;
        }
        return response.data;
      },
      async onQueryStarted({ id, payload }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(updateUser({ id, ...payload }));
        } catch (error) {
          console.error("Updating user failed: ", error);
        }
      },
    }),
  }),
});

export const { useGetCustomerQuery, useUpdateCustomerMutation } =
  customerServiceSlice;
