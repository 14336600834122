import { createSlice } from "@reduxjs/toolkit";

import { appStorage } from "lib/localStorage";
import { User } from "lib/types";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: appStorage.getItem<User>("user") ?? undefined,
  },
  reducers: {
    setUser: (state, { payload: user }) => {
      if (!user) {
        appStorage.removeItem("user");
        state.user = undefined;
        return;
      }

      appStorage.setItem("user", user);
      state.user = user;
    },
    updateUser: (state, { payload: user }) => {
      state.user = {
        ...state.user,
        ...user,
      };

      appStorage.setItem("user", state.user!);
    },
  },
});

export const { setUser, updateUser } = userSlice.actions;

export default userSlice.reducer;
