import { useState } from "react";

import { classNames } from "lib/classNames";

import styles from "./Switch.module.scss";

type Props = {
  switched?: boolean;
};

export function Switch({ switched }: Props) {
  const [toggled, setToggle] = useState(switched);

  return (
    <div className={classNames(styles.toggleContainer, styles.togglePrimary)}>
      <input
        type="checkbox"
        id="switch"
        defaultChecked={toggled}
        onChange={({ target: { checked } }) => setToggle(checked)}
        className={styles.toggleDefault}
      />
      <span className={styles.toggleDefault} />
    </div>
  );
}
