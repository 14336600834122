import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { Spinner } from "components/Spinner";
import { Text } from "components/Text";

import {
  paymentServiceSlice,
  useInitSwishPaymentMutation,
} from "lib/slices/paymentServiceSlice";
import store from "lib/store";

import styles from "./InvoicePaymentCard.module.scss";

type Props = {
  price?: string;
  currency?: string;
  isOpen: boolean;
  invoiceId: string;
};

export async function pollSwishStatus(swishId: string, invoiceId: string) {
  const statusRequest = store.dispatch(
    paymentServiceSlice.endpoints.getSwishStatus.initiate(
      { swishId, invoiceId },
      {
        subscriptionOptions: { pollingInterval: 3000 },
      },
    ),
  );

  await statusRequest;
  return statusRequest.unsubscribe;
}

export function PaymentSwish({ price, currency, invoiceId, isOpen }: Props) {
  const { t } = useTranslation();

  const [, res] = useInitSwishPaymentMutation({
    fixedCacheKey: "swish-payment",
  });

  const openSwishApp = (url: string) => {
    window.location.assign(url);
  };

  // ! EDGE CASE WARNING !
  // This polling should be removed and be handled by the payment service backend.
  // Swish should send a webhook to our payment service, which updates our invoice service.
  // Due to no webhook implementation from Swish's side, the client has to check if the status has changed.
  // If the user scans the QR code, pays on the Swish app, but then for X reason loses internet connection,
  // and doesn't hit the /swish/status endpoint, the payment will go through but the status of the invoice
  // will still be "unpaid" as the payment service wouldn't have been able to call the invoice service.
  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    const swishId = res.data?.id;
    if (isOpen && swishId) {
      // Starts to poll /swish/status endpoint every 3 seconds
      pollSwishStatus(swishId, invoiceId).then((statusRequest) => {
        unsubscribe = statusRequest;
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [isOpen, res]);

  if (isMobile) {
    return (
      <div className={styles.paymentSwish}>
        <Text as="p" style="body" strong>
          {t("features.invoice.payment.swishPaying", { price, currency })}
        </Text>

        {res.isLoading ? (
          <div className={styles.spinner}>
            <Spinner />
          </div>
        ) : (
          <Button
            text={t("features.invoice.payment.openSwishMobile")}
            intent="primary"
            onClick={() => openSwishApp(res.data?.url as string)}
          />
        )}
      </div>
    );
  }

  return (
    <div className={styles.paymentSwish}>
      <Text as="p" style="body" strong>
        {t("features.invoice.payment.swishPaying", { price, currency })}
      </Text>
      <Text as="p" style="body">
        {t("features.invoice.payment.openSwish")}
      </Text>

      <div className={styles.imgContainer}>
        {res.data?.qrCode ? (
          <img src={`data:image/png;base64,${res.data.qrCode}`} />
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}
