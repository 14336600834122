import styles from "./Checkbox.module.scss";

type Props = {
  checked?: boolean;
};

export function Checkbox({ checked }: Props) {
  return (
    <input
      type="checkbox"
      className={styles.checkbox}
      checked={checked}
      readOnly
    />
  );
}
