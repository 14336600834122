import { ReactElement, useState } from "react";

// import { Button } from "components/Button";
// import { Icon } from "components/Icon";
// import { Text } from "components/Text";
// import { Tooltip } from "components/Tooltip";
import styles from "./Tabs.module.scss";
import { TabsHeaders } from "./TabsHeaders";

type Props = {
  tabs: { title: string; content: ReactElement; tooltipText?: string }[];
  onChange?: (index: number) => void;
};

export function Tabs({ tabs, onChange }: Props) {
  const [activeIndex, setActiveIndex] = useState(0);
  // const borderPos = (100 / tabs.length) * activeIndex;

  const _onChange = (index: number) => {
    setActiveIndex(index);
    onChange?.(index);
  };

  return (
    <>
      <div className={styles.tabsContainer}>
        <TabsHeaders
          tabs={tabs}
          activeIndex={activeIndex}
          onChange={_onChange}
        />
      </div>

      {tabs[activeIndex].content}
    </>
  );
}
