import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { SHOW_FULL_LOGO } from "components/Navbar/Navbar";
import { Text } from "components/Text";

import useMediaQuery from "lib/hooks/useMediaQuery";
import { IRootState } from "lib/store";
import { Appearance } from "lib/types";

import styles from "./Footer.module.scss";
import { FooterLink } from "./FooterLink";

export type FooterLink = {
  title: string;
  href: string;
  external?: boolean;
};

type Props = {
  logoSrc: string;
  companyName: string;
  appearance: Appearance;
  links: FooterLink[];
  socials: FooterLink[];
};

export function Footer({
  companyName,
  appearance = "dark",
  logoSrc,
  links,
  socials,
}: Props) {
  const { t } = useTranslation();
  const isTablet = useMediaQuery("(min-width: 768px)");
  const theme = useSelector((state: IRootState) => state.theme.theme);
  const showFullLogo = SHOW_FULL_LOGO.includes(theme);

  return (
    <footer
      className={styles.footer}
      data-appearance={isTablet ? "dark" : appearance}
    >
      <div className={styles.company}>
        <span
          className={styles.logoContainer}
          style={{
            padding: showFullLogo ? 0 : undefined,
            backgroundColor: showFullLogo ? "transparent" : undefined,
          }}
        >
          <img
            alt={t("common.logoOf", { company: companyName })}
            src={logoSrc}
          />
        </span>
        <Text as="p" style="h2">
          {companyName}
        </Text>
      </div>

      <div>
        <section className={styles.links}>
          {links.map((link, key) => (
            <FooterLink key={key} link={link} appearance={appearance} />
          ))}
        </section>

        <section className={styles.socials}>
          <Text as="p" style="body-small">
            {t("components.footer.follow")}
          </Text>
          {socials.map((link, key) => (
            <FooterLink key={key} link={link} appearance={appearance} />
          ))}
        </section>
      </div>

      <Text as="p" style="body-small" className={styles.poweredBy}>
        <>
          {t("components.footer.poweredBy")}
          <strong>{t("components.footer.inio")}</strong>
        </>
      </Text>
    </footer>
  );
}
