import { useState } from "react";

import { Button } from "components/Button";
import { Card } from "components/Card";

import { classNames } from "lib/classNames";

import styles from "./CardAccordion.module.scss";

type Props = {
  btn: JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
  onChange?: (isOpen: boolean) => void;
};

export function CardAccordion({ btn, children, onChange }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    onChange?.(!isOpen);
  };

  return (
    <Card noPadding className={classNames({ [styles.cardBorder]: isOpen })}>
      <Button onClick={toggleAccordion} intent="clear" fill>
        {btn}
      </Button>
      <div
        className={classNames(styles.accordionContent, {
          [styles.accordionContentShow]: isOpen,
        })}
      >
        {children}
      </div>
    </Card>
  );
}
