import { initReactI18next } from "react-i18next";

import i18n from "i18next";

import { appStorage } from "lib/localStorage";

import localeEn from "../i18n/en.json";
import localeSv from "../i18n/sv.json";

export type SupportedLanguages = keyof typeof resources;

const resources = {
  en: { translation: localeEn },
  sv: { translation: localeSv },
};

export const countryCodeMapper = {
  en: "en-EN",
  sv: "sv-SE",
};

i18n.use(initReactI18next).init({
  resources,
  lng: appStorage.getItem<SupportedLanguages>("language") ?? "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
