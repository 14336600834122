import { TouchList, useEffect, useRef, useState } from "react";

import { Text } from "components/Text";

import { classNames } from "lib/classNames";

import styles from "./Tooltip.module.scss";

type Props = {
  text: string;
  children: JSX.Element | JSX.Element[];
};

export function Tooltip({ text, children }: Props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  // Default tooltip to start from the left
  useEffect(() => {
    if (!tooltipRef.current) {
      return;
    }

    tooltipRef.current.style.left = "auto";
    tooltipRef.current.style.right = "0px";
  }, [tooltipRef]);

  // Hides tooltip on scroll (needed for mobile)
  useEffect(() => {
    const hideTooltip = () => {
      setShowTooltip(false);
    };

    window.addEventListener("scroll", hideTooltip);

    return () => {
      window.removeEventListener("scroll", hideTooltip);
    };
  }, []);

  const onMouseEnter = (node: HTMLDivElement) => {
    setShowTooltip(true);

    if (!tooltipRef.current) {
      return;
    }

    // Checks if tooltip will overflow
    if (
      node.getBoundingClientRect().left +
        tooltipRef.current.getBoundingClientRect().width >
      window.innerWidth
    ) {
      tooltipRef.current.style.left = "auto";
      tooltipRef.current.style.right = "0px";
    } else {
      tooltipRef.current.style.left = "var(--spacing-12)";
      tooltipRef.current.style.right = "auto";
    }
  };

  const onMouseLeave = () => {
    setShowTooltip(false);
  };

  const onTouch = (targetTouches: TouchList) => {
    if (!tooltipRef.current) {
      return;
    }

    setShowTooltip(!showTooltip);
    tooltipRef.current.style.position = "fixed";
    tooltipRef.current.style.top = `${targetTouches[0].clientY + 12}px`;
    tooltipRef.current.style.width = "fit-content";
    tooltipRef.current.style.right = "8px";
  };

  return (
    <div className={styles.tooltipContainer}>
      <div
        onMouseEnter={(e) => onMouseEnter(e.currentTarget)}
        onTouchStart={(e) => onTouch(e.targetTouches)}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </div>
      <div
        ref={tooltipRef}
        className={classNames(styles.tooltip, {
          [styles.tooltipShow]: showTooltip,
        })}
      >
        <Text as="p" style="detail">
          {text}
        </Text>
      </div>
    </div>
  );
}
