import { CSSProperties } from "react";

import styles from "./LayoutPage.module.scss";

interface CustomStyles extends CSSProperties {
  "--spacing-top": string;
}

type Props = {
  children: JSX.Element | JSX.Element[];
  paddingTop?: number;
  bgColor?: string;
};

export function LayoutPage({
  children,
  paddingTop = 160,
  bgColor = "var(--layer-3)",
}: Props) {
  return (
    <div
      className={styles.page}
      style={
        {
          backgroundColor: bgColor,
          "--spacing-top": `${paddingTop}px`,
        } as CustomStyles
      }
    >
      {children}
    </div>
  );
}
