import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "components/Button";
import { Text } from "components/Text";

import { classNames } from "lib/classNames";
import useIsMobile from "lib/hooks/useIsMobile";
import { useOutsideClick } from "lib/hooks/useOutsideClick";
import { IRootState } from "lib/store";
import { Appearance } from "lib/types";

import { LanguageSwitcher } from "./LanguageSwitcher";
import { Menu } from "./Menu";
import styles from "./Navbar.module.scss";

type Props = {
  companyName: string;
  logoSrc: string;
};

const SHOW_BANNER = ["/"];
export const SHOW_FULL_LOGO = [
  "allente",
  "dentolo",
  "unicef",
  "redcross",
  "epark",
];

export function Navbar({ companyName, logoSrc }: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const isMobile = useIsMobile();
  const theme = useSelector((state: IRootState) => state.theme.theme);
  const [showMenu, setShowMenu] = useState(false);
  const [showLanguageSwitcher, setShowLanguageSwitcher] = useState(false);

  const [appearance, setAppearance] = useState<Appearance>("dark");
  const [showBanner, setShowBanner] = useState(
    SHOW_BANNER.includes(location.pathname),
  );

  const hamburgerContainer = useRef(null);
  const languageSwitcherRef = useRef(null);
  const showFullLogo = SHOW_FULL_LOGO.includes(theme);

  useOutsideClick(
    [
      {
        key: "hamburger",
        element: hamburgerContainer,
      },
      {
        key: "language",
        element: languageSwitcherRef,
      },
    ],
    (type?: string) => {
      if (type === "hamburger") {
        setShowMenu(false);
      } else if (type === "language") {
        setShowLanguageSwitcher(false);
      }
    },
  );

  useEffect(() => {
    const uuidRegex =
      /^\/\d+$|^\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    const showBanner =
      SHOW_BANNER.includes(location.pathname) ||
      (/^\/(\d+|[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})(\/details)?$/.test(
        location.pathname,
      ) &&
        !isMobile) ||
      uuidRegex.test(location.pathname);

    setShowBanner(showBanner);
    setAppearance(showBanner ? "dark" : "light");
  }, [location, isMobile]);

  const navigate = useNavigate();
  const handleClickOnLogo = () => {
    navigate("/");
  };

  return (
    <>
      {showBanner && (
        <span className={styles.headerImage}>
          <picture>
            <source
              type="image/webp"
              srcSet={`/assets/${theme}/image-block-desktop.webp`}
              media="(min-width: 1025px)"
            />
            <source
              type="image/webp"
              srcSet={`/assets/${theme}/image-block-mobile.webp`}
            />
            <img src={`/assets/${theme}/image-block-mobile.webp`} alt="" />
          </picture>
        </span>
      )}

      <nav className={styles.navbar} data-appearance={appearance}>
        <div
          className={styles.leftContainer}
          onClick={handleClickOnLogo}
          role="button"
          tabIndex={0}
          aria-label={t("accessibilityText.navigateToHomePage")}
        >
          <span
            className={styles.logoContainer}
            style={{
              padding: showFullLogo ? 0 : undefined,
              backgroundColor: showFullLogo ? "transparent" : undefined,
            }}
          >
            <img
              alt={t("common.logoOf", { company: companyName })}
              src={logoSrc}
            />
          </span>

          <Text as="p" style="h2">
            {companyName}
          </Text>
        </div>

        <div className={styles.dropdowns}>
          <LanguageSwitcher
            isOpen={showLanguageSwitcher}
            onClick={() => setShowLanguageSwitcher(!showLanguageSwitcher)}
            appearance={appearance}
            ref={languageSwitcherRef}
          />

          <span className={styles.menuContainer} ref={hamburgerContainer}>
            <Button intent="clear" onClick={() => setShowMenu(!showMenu)}>
              <i
                className={classNames(styles.hamburger, {
                  [styles.dark]: appearance === "light",
                  [styles.active]: showMenu,
                })}
              />
            </Button>

            <Menu isOpen={showMenu} onClick={() => setShowMenu(false)} />
          </span>
        </div>
      </nav>
    </>
  );
}
