import { useTranslation } from "react-i18next";

import { Card } from "components/Card";
import { Text } from "components/Text";

import {
  convertTimestampToDDMMYY,
  formatCurrencyWithNumberFormat,
} from "lib/helpers";

import styles from "./InvoiceActivity.module.scss";

type Activity = {
  date: string;
  type: string;
  amount?: string;
  currency?: string;
};

interface GroupedTimeline {
  [key: string]: Activity[];
}

type Props = {
  timeline: Activity[];
  language: string;
};

export function InvoiceActivity({ timeline, language }: Props) {
  const { t } = useTranslation();

  // Group invoice activity by month
  const groupedTimeline = timeline.reduce(
    (acc: GroupedTimeline, entry: Activity) => {
      const date = new Date(entry.date);
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();
      const monthYear = `${month}-${year}`;

      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(entry);

      return acc;
    },
    {},
  );

  // Sort the grouped timeline by month and year
  const sortedGroupedTimeline = Object.keys(groupedTimeline)
    .sort((a, b) => {
      const [aMonth, aYear] = a.split("-");
      const [bMonth, bYear] = b.split("-");
      const dateA = new Date(`${aYear}-${aMonth}-01`);
      const dateB = new Date(`${bYear}-${bMonth}-01`);
      return dateB.getTime() - dateA.getTime();
    })
    .reduce((acc: GroupedTimeline, key) => {
      acc[key] = groupedTimeline[key].reverse();
      return acc;
    }, {});

  return (
    <Card>
      <div className={styles.activityContainer}>
        {Object.keys(sortedGroupedTimeline).map((month) => (
          <div key={month} className={styles.monthContainer}>
            <Text as="h4" style="body" color="---content-emphasis">
              {month.replace("-", " ")}
            </Text>

            <div>
              {sortedGroupedTimeline[month].map((activity, index) => (
                <div key={index} className={styles.activity}>
                  <div>
                    <Text as="p" style="body-small">
                      {t(`features.invoice.history.${activity.type}`)}
                    </Text>
                    <Text as="p" style="body" color="--content-muted">
                      {convertTimestampToDDMMYY(activity.date)}
                    </Text>
                  </div>

                  {activity.amount && (
                    <Text as="p" style="body">
                      {formatCurrencyWithNumberFormat(
                        activity.amount,
                        language,
                        activity.currency,
                        true,
                      )}
                    </Text>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}
