import { countryCodeMapper } from "utils/i18n";

export function formatNumberToCurrency(amount: number): string {
  return amount.toLocaleString("sv-SE", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
}

export function formatCurrencyWithNumberFormat(
  amount: string | number,
  lng: string = "sv",
  currency?: string,
  lowercased?: boolean,
) {
  let cur;
  try {
    cur = new Intl.NumberFormat(
      `${countryCodeMapper[lng as keyof typeof countryCodeMapper]}`,
      {
        style: "currency",
        currency: currency,
      },
    ).format(Number(amount));
  } catch {
    cur = `${amount} ${currency}`;
  }

  if (lowercased) return cur?.toLowerCase();

  return cur;
}

export const copyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
};

export function convertTimestampToDDMMYY(timestamp: string): string {
  const date = new Date(timestamp);

  // Pad single digit days and months with leading zeros
  const padWithZero = (num: number) => (num < 10 ? "0" + num : num.toString());

  const day = padWithZero(date.getUTCDate());
  const month = padWithZero(date.getUTCMonth() + 1); // Months are zero-based
  const year = date.getUTCFullYear();

  return `${day}-${month}-${year}`;
}

// Convert kebab cased words into camel cased
export function convertKebabIntoCamelCased(word: string) {
  return word.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
}
