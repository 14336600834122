import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { pollSwishStatus } from "features/invoice/components/InvoicePaymentCard/PaymentSwish";
import { LayoutPage } from "layouts/LayoutPage";

import { Button } from "components/Button";
import { Card } from "components/Card";
import { Icon } from "components/Icon";
import { Spinner } from "components/Spinner";
import { Text } from "components/Text";

import { useHead } from "lib/hooks/useHead";

import styles from "./PagePayment.module.scss";

type Props = {
  invoiceId: string;
  isLoading?: boolean;
};

export function PagePaymentStatus({ invoiceId, isLoading }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useHead(t("pages.paymentStatus.confirmed"));
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const swishId = searchParams.get("ref");
    if (!swishId || !isLoading) {
      return;
    }

    let unsubscribe: (() => void) | undefined;

    // Starts to poll /swish/status endpoint every 3 seconds
    pollSwishStatus(swishId, invoiceId).then((statusRequest) => {
      unsubscribe = statusRequest;
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [searchParams, isLoading]);

  if (isLoading) {
    return (
      <LayoutPage bgColor="transparent" paddingTop={120}>
        <div className={styles.paymentConfirmedContainer}>
          <Card className={styles.paymentConfirmedCard}>
            <Spinner />
          </Card>
        </div>
      </LayoutPage>
    );
  }

  return (
    <LayoutPage bgColor="transparent" paddingTop={120}>
      <div className={styles.paymentConfirmedContainer}>
        <Card className={styles.paymentConfirmedCard}>
          <Icon name="tick" size={94} intent="--color-green" />

          <div>
            <Text as="h1" style="h2">
              {t("pages.paymentStatus.confirmed")}
            </Text>

            <Text as="p" style="body" color="--content-subtle">
              {t("pages.paymentStatus.messageConfirmed")}
            </Text>
          </div>

          <Button
            intent="secondary"
            text={t("common.continue")}
            onClick={() => navigate(`/${invoiceId}`)}
          />
        </Card>
      </div>
    </LayoutPage>
  );
}
