import { ReactComponent as alertTriangle } from "./svgs/alert-triangle.svg";
import { ReactComponent as arrowLeft } from "./svgs/arrow-left.svg";
import { ReactComponent as arrowRight } from "./svgs/arrow-right.svg";
import { ReactComponent as arrowUpRight } from "./svgs/arrow-up-right.svg";
import { ReactComponent as calendar } from "./svgs/calendar.svg";
import { ReactComponent as card } from "./svgs/card.svg";
import { ReactComponent as chevronDown } from "./svgs/chevron-down.svg";
import { ReactComponent as chevronRight } from "./svgs/chevron-right.svg";
import { ReactComponent as chevronUp } from "./svgs/chevron-up.svg";
import { ReactComponent as close } from "./svgs/close.svg";
import { ReactComponent as copy } from "./svgs/copy.svg";
import { ReactComponent as creditCard } from "./svgs/credit-card.svg";
import { ReactComponent as cvc } from "./svgs/cvc.svg";
import { ReactComponent as download } from "./svgs/download.svg";
import { ReactComponent as globe } from "./svgs/globe.svg";
import { ReactComponent as hamburger } from "./svgs/hamburger.svg";
import { ReactComponent as infoCircle } from "./svgs/info-circle.svg";
import { ReactComponent as lock } from "./svgs/lock.svg";
import { ReactComponent as mail } from "./svgs/mail.svg";
import { ReactComponent as starFilled } from "./svgs/star-filled.svg";
import { ReactComponent as star } from "./svgs/star.svg";
import { ReactComponent as tick } from "./svgs/tick.svg";
import { ReactComponent as user } from "./svgs/user.svg";

export const icons = {
  hamburger,
  arrowLeft,
  arrowRight,
  arrowUpRight,
  star,
  starFilled,
  chevronRight,
  chevronDown,
  infoCircle,
  mail,
  download,
  copy,
  card,
  user,
  lock,
  cvc,
  creditCard,
  calendar,
  alertTriangle,
  chevronUp,
  close,
  tick,
  globe,
};
