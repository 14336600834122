import { ChangeEvent, FocusEvent, useState } from "react";

import { BaseInputTemplateProps } from "@rjsf/utils";

import { Icon, Icons } from "components/Icon";
import { Text } from "components/Text";

import { classNames } from "lib/classNames";

import styles from "./Input.module.scss";

/**
 * This component extends the BaseInputTemplate from rjsf.
 * https://rjsf-team.github.io/react-jsonschema-form/docs/advanced-customization/custom-templates#baseinputtemplate
 */
export function Input(props: BaseInputTemplateProps) {
  const [smallLabel, setSmallLabel] = useState(false);

  const {
    id,
    type,
    disabled,
    onChange,
    onChangeOverride,
    onBlur,
    onFocus,
    value,
    options,
    label,
    maxLength,
  } = props;

  const onTextChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange?.(value === "" ? undefined : value);
  };
  const onTextBlur = ({ target: { value } }: FocusEvent<HTMLInputElement>) => {
    onBlur?.(id, value);
    setSmallLabel(false);
  };
  const onTextFocus = ({ target: { value } }: FocusEvent<HTMLInputElement>) => {
    onFocus?.(id, value);
    setSmallLabel(true);
  };

  return (
    <div className={styles.inputContainer}>
      {options.icon && <Icon name={options.icon as Icons} />}
      <div className={styles.input}>
        <Text
          as="label"
          style="body"
          htmlFor={id}
          color="--content-subtle"
          className={classNames(styles.label, {
            [styles.labelSmall]: smallLabel || value !== undefined,
          })}
        >
          {label}
        </Text>
        <input
          id={id}
          name={props.name}
          type={type}
          disabled={disabled}
          defaultValue={value}
          maxLength={maxLength}
          onChange={onChangeOverride || onTextChange}
          autoComplete={options.autocomplete}
          onBlur={onTextBlur}
          onFocus={onTextFocus}
        />
        {options.additionalIcon && (
          <span className={styles.additionalIcon}>
            <Icon name={options.additionalIcon as Icons} />
          </span>
        )}
      </div>
    </div>
  );
}
